import axios from '@/libs/axios';

export default {
  /**
   * Send request for get skills
   * @returns {Object}
   */
  async skills() {
    const res = await axios.get('dictionaries/skill');
    return res;
  },
};
