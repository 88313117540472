<template>
  <div class="user-cabinet__inner">
    <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    <div class="user-cabinet__form">
      <ValidationObserver slim v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onUpdate)">
          <WrapperProfileTextField title="About">
            <template #main>
              <textarea
                v-model="form.about"
                class="form-control icon-pen-about"
                name="about"
                cols="30"
                rows="10"
                placeholder="Write something about yourself..."
              ></textarea>
            </template>
          </WrapperProfileTextField>

          <WrapperProfileTextField title="Website">
            <template #main>
              <ValidationProvider
                name="Website"
                rules="website"
                v-slot="{ errors, failed }">
                <div class="form-row" :class="{ 'has-error': failed }">
                  <input
                    name="website"
                    v-model="form.website"
                    class="form-control icon-website"
                    type="text"
                    placeholder="Website"
                  />
                  <span class="help-message">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </template>
          </WrapperProfileTextField>

          <WrapperProfileTextField title="Skills">
            <template #main>
              <multiselect
                v-model="selectedSkills"
                :options="skillsOptions"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                placeholder="Pick some"
                label="name"
                track-by="name"
              >
              </multiselect>
            </template>
          </WrapperProfileTextField>

          <div class="user-cabinet__btn right">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Alert from '@/components/Alert.vue';

import ProfileTextField from '@/components/wrapper/ProfileTextField.vue';

import UserService from '@/services/UserService';
import DictionariesServices from '@/services/DictionariesServices';

import alert from '@/mixins/alert';
import content from '@/mixins/content';

export default {
  mixins: [alert, content],
  components: {
    Multiselect,
    Alert,
    WrapperProfileTextField: ProfileTextField,
  },
  async created() {
    await this.setSkills();
    await this.setDefaultData();
  },
  data() {
    return {
      form: {
        about: null,
        website: null,
        skills: [],
      },
      selectedSkills: [],
      skillsOptions: [],
    };
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
  },
  methods: {
    setDefaultData() {
      this.form.about = this.me.about;
      this.form.website = this.me.website;
      const { skills } = this.me;

      this.skillsOptions.forEach((skill) => {
        if (skills.includes(skill.id)) {
          this.selectedSkills.push(skill);
        }
      });
    },
    async setSkills() {
      try {
        const { data } = await DictionariesServices.skills();
        this.skillsOptions = data;
      } catch (e) {
        this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
    addSkillsToForm() {
      this.form.skills = this.selectedSkills.map((s) => s.id);
    },
    async onUpdate() {
      try {
        this.addSkillsToForm();
        this.removeAlert();
        await UserService.update(this.form);
        const { data } = await UserService.current();
        this.$store.commit('SET_ME', data);
        document.querySelector('#app').scrollIntoView(true);
        this.setAlert({ textArg: 'Successfully updated', variantArg: 'success' });
        setTimeout(() => {
          this.removeAlert();
        }, 5000);
      } catch (e) {
        document.querySelector('#app').scrollIntoView(true);
        this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
::v-deep .multiselect__option--highlight {
  background: #489dd9;
  outline: none;
  color: #fff;
}
::v-deep span.multiselect__option.multiselect__option--highlight::after {
  background: #489dd9;
}
::v-deep .multiselect__tag {
  background: #489dd9;
}
.page-layout {
  background-image: none !important;
}
</style>
